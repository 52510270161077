import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import maping from '@/meta'
Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    name: 'Home',
    meta:maping.Home,
    component: Home,
},
    {
        path: '/ExternalLinkHandler ',
        name: 'ExternalLinkHandler',
        meta: {
            nameEn: '外部链接',
        },
        props: true, // 允许传递路由参数
        component: () => import('@/components/ExternalLinkHandler.vue')
    },
    {
        path: '/geoevaluation',
        name: 'geoevaluation',
        meta: {
            nameEn: '油藏地质评价服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/comment.vue')
    },
    {
        path: '/schemedesign',
        name: 'schemedesign',
        meta: {
            nameEn: '开发设计方案服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/exploit.vue')
    },
    {
        path: '/featuretech',
        name: 'featuretech',
        meta: {
            nameEn: '天然裂缝预测技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/forecast.vue')
    },
    {
        path: '/ground',
        name: 'ground',
        meta: {
            nameEn: '地质力学研究技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/ground.vue')
    },
    {
        path: '/geomodeling',
        name: 'geomodeling',
        meta: {
            nameEn: '三维地质力学建模技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/modeling.vue')
    },
    {
        path: '/datainner',
        name: 'datainner',
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/datainner.vue')
    },
    {
        path: '/recognition',
        name: 'recognition',
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/recognition.vue')
    },
    {
        path: '/eur',
        name: 'eur',
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/eur.vue')
    },
    {
        path: '/fibernew',
        name: 'fibernew',
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/fiber.vue')
    },
    {
        path: '/online',
        name: 'online',
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/online.vue')
    },
    {
        path: '/integratedstimulation',
        name: 'integratedstimulation',
        meta: {
            nameEn: '一体化增产技术及配套技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/special.vue')
    },
    {
        path: '/fullscale',
        name: 'fullscale',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/singlethird/fracture.vue')
    },
    {
        path: '/design',
        name: 'design',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/singlethird/evaluation.vue')
    },
    {
        path: '/stimulation',
        name: 'stimulation',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/singlethird/increasing.vue')
    },
    {
        path: '/acidizing',
        name: 'acidizing',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/singlethird/acidizing.vue')
    },


    {
        path: '/highfrequency',
        name: 'highfrequency',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoringthird/highfrequency.vue')
    },
    {
        path: '/chemicaltracer',
        name: 'chemicaltracer',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoringthird/microchemistry.vue')
    },
    {
        path: '/solidtracer',
        name: 'solidtracer',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoringthird/longlasting.vue')
    },
    {
        path: '/microseismic',
        name: 'microseismic',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoringthird/microseismic.vue')
    },
    {
        path: '/wfemm',
        name: 'wfemm',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoringthird/electromagnetism.vue')
    },
    // {
    //     path: '/FracturingMonitoring',
    //     name: 'FracturingMonitoring',
    //     component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoringthird/fiber.vue')
    // },
    {
        path: '/watercontrolproppant',
        name: 'watercontrolproppant',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/watercontrol.vue')
    },
    {
        path: '/integratedacid',
        name: 'integratedacid',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/viscousacid.vue')
    },
    {
        path: '/drypowder',
        name: 'drypowder',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/specialpolymer.vue')
    },
    {
        path: '/fiber',
        name: 'fiber',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/sandcontrol.vue')
    },
    {
        path: '/nanofluid',
        name: 'nanofluid',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/nanocomposite.vue')
    },
    {
        path: '/irregular',
        name: 'irregular',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/irregular.vue')
    },
    {
        path: '/diverting',
        name: 'diverting',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/conventional.vue')
    },
    {
        path: '/selfbonding',
        name: 'selfbonding',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/consolidating.vue')
    },
    {
        path: '/proppant',
        name: 'proppant',
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/specIncProduct/composite.vue')
    },
    {
        path: '/featuredproducts',
        name: 'featuredproducts',
        meta: {
            nameEn: '非常规一体化增产服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/connect.vue')
    },
    {
        path: '/stimulationmonitoring',
        name: 'stimulationmonitoring',
        meta: {
            nameEn: '增产风险服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/subordinate/monitoring.vue')
    },
    {
        path: '/unconventional',
        name: 'unconventional',
        meta: {
            nameEn: '非常规实时迭代压裂技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/unconventional.vue')
    },
    {
        path: '/fracturing',
        name: 'fracturing',
        meta: {
            nameEn: '实时迭代生产监测技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/realtime.vue')
    },
    {
        path: '/area',
        name: 'area',
        meta: {
            nameEn: '区域开发工程迭代优化技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/area.vue')
    },
    {
        path: '/scene',
        name: 'scene',
        meta: {
            nameEn: '现场地质工程师服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/scene.vue')
    },
    {
        path: '/unite',
        name: 'unite',
        meta: {
            nameEn: '地质工程相结合的项目研究服务',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/unite.vue')
    },
    {
        path: '/drilling',
        name: 'drilling',
        meta: {
            nameEn: '实时迭代钻井技术',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/services/drill.vue')
    },
    {
        path: '/TurnkeySolution',
        name: 'TurnkeySolution',
        meta: {
            nameEn: '增产整体解决方案',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/solute/increase.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            nameEn: '关于我们',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/about.vue')
    },
    {
        path: '/inter',
        name: 'inter',
        meta: {
            nameEn: '新入口',
            keepAlive: true,
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/inter.vue')
    },
    {
        path: '/intelligentengineering',
        name: 'intelligentengineering',
        meta: {
            nameEn: '智能工程迭代优化GPTs',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/ground/intelligent.vue')
    },
    {
        path: '/FiberOptic',
        name: 'FiberOptic',
        meta: {
            nameEn: '油气田开发动态监测GPTs3',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/ground/distributed.vue')
    },
    {
        path: '/digital',
        name: 'digital',
        meta: {
            nameEn: '油气田开发动态监测GPTs3',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/ground/number.vue')
    },
    {
        path: '/Feedback',
        name: 'Feedback',
        meta: {
            nameEn: '作业项目服务评价反馈',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/components/commentFeedback.vue')
    },
    {
        path: '/netFeedback',
        name: 'netFeedback',
        meta: {
            nameEn: '网页使用体验反馈',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/components/netFeedback.vue')
    },
    {
        path: '/ProductFeedback',
        name: 'ProductFeedback',
        meta: {
            nameEn: '产品评价反馈',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/components/productFeedback.vue')
    },
    {
        path: '/logging',
        name: 'logging',
        meta: {
            nameEn: '油气田开发动态监测GPTs3',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/ground/gasreservoir.vue')
    },
    {
        path: '/CasingDamage',
        name: 'CasingDamage',
        component: () => import( /* webpackChunkName: "about" */ '@/views/gasreservoirthird/casingdamage.vue')
    },
    {
        path: '/ArrayImaging',
        name: 'ArrayImaging',
        component: () => import( /* webpackChunkName: "about" */ '@/views/gasreservoirthird/horizontalwell.vue')
    },

    {
        path: '/DigitalCuttings',
        name: 'DigitalCuttings',
        meta: {
            nameEn: '数字岩屑技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/cuttings.vue')
    },
    {
        path: '/DigitalCore',
        name: 'DigitalCore',
        meta: {
            nameEn: '数字岩心技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/core.vue')
    },
    {
        path: '/OutsideTubing',
        name: 'OutsideTubing',
        meta: {
            nameEn: '数字岩心技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/outside.vue')
    },
    {
        path: '/OutsideCasing',
        name: 'OutsideCasing',
        meta: {
            nameEn: '数字岩心技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/casing.vue')
    },

    {
        path: '/ccus',
        name: 'ccus',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/ccus.vue')
    },
    {
        path: '/pipeline',
        name: 'pipeline',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/pipeline.vue')
    },
    {
        path: '/neighboringwell',
        name: 'neighboringwell',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/neighboringwell.vue')
    },
    {
        path: '/leakdetection',
        name: 'leakdetection',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/leakdetection.vue')
    },
    {
        path: '/waterdetection',
        name: 'waterdetection',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/waterdetection.vue')
    },
    {
        path: '/profilinginjection',
        name: 'profilinginjection',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/profilinginjection.vue')
    },

    {
        path: '/GenerationMonitoring',
        name: 'GenerationMonitoring',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/fibergeneration.vue')
    },
    {
        path: '/WaterFinding',
        name: 'WaterFinding',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/fiberoptic.vue')
    },
    {
        path: '/LeakFinding',
        name: 'LeakFinding',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/opticalfiber.vue')
    },
    {
        path: '/FracturingMonitoring',
        name: 'FracturingMonitoring',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/offsetwell.vue')
    },
    {
        path: '/StorageMonitoring',
        name: 'StorageMonitoring',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/ccus.vue')
    },
    {
        path: '/PipelineMonitoring',
        name: 'PipelineMonitoring',
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/pipe.vue')
    },
    {
        path: '/plug',
        name: 'plug',
        component: () => import( /* webpackChunkName: "about" */ '@/views/new/plug.vue')
    },
    {
        path: '/specialengineering',
        name: 'specialengineering',
        meta: {
            nameEn: '特殊工程技术服务系列',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/ground/special.vue')
    },
    {
        path: '/RHsupramolecular',
        name: 'RHsupramolecular',
        meta: {
            nameEn: 'RH超分子多功能调驱技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/rhmodie.vue')
    },
    {
        path: '/emperatureresistance',
        name: 'emperatureresistance',
        meta: {
            nameEn: '抗高温高强度复合调堵技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/temperature.vue')
    },
    {
        path: '/core-shell',
        name: 'core-shell',
        meta: {
            nameEn: '核壳异性水基微球调驱技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/necleus.vue')
    },
    {
        path: '/self-assembling',
        name: 'self-assembling',
        meta: {
            nameEn: '自聚集水基微球调驱技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/water.vue')
    },
    {
        path: '/waterreservoir',
        name: 'waterreservoir',
        meta: {
            nameEn: '底水油藏堵水技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/groundthrid/bottom.vue')
    },
    {
        path: '/microbialoil',
        name: 'microbialoil',
        meta: {
            nameEn: '底水油藏堵水技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/inhancethird/microorganism.vue')
    },
    {
        path: '/hightemperature',
        name: 'hightemperature',
        meta: {
            nameEn: '底水油藏堵水技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/inhancethird/gas.vue')
    },
    {
        path: '/emulsification',
        name: 'emulsification',
        meta: {
            nameEn: '底水油藏堵水技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/inhancethird/heavyoil.vue')
    },
    {
        path: '/nanoimbibing',
        name: 'nanoimbibing',
        meta: {
            nameEn: '纳米渗吸剂提高采收率技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/inhancethird/dense.vue')
    },
    {
        path: '/petroleumenzyme',
        name: 'petroleumenzyme',

        component: () => import( /* webpackChunkName: "about" */ '@/views/oilwaterthird/enzyme.vue')
    },
    {
        path: '/fluidcavitation',
        name: 'fluidcavitation',
        component: () => import( /* webpackChunkName: "about" */ '@/views/oilwaterthird/liquid.vue')
    },
    {
        path: '/waterlockremoval',
        name: 'waterlockremoval',
        component: () => import( /* webpackChunkName: "about" */ '@/views/oilwaterthird/gaswell.vue')
    },
    {
        path: '/CSGdrilling',
        name: 'CSGdrilling',
        component: () => import( /* webpackChunkName: "about" */ '@/views/specialthird/tube.vue')
    },
    {
        path: '/seismoge',
        name: 'seismoge',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/total.vue')
    },
    {
        path: '/giantmagnetic',
        name: 'giantmagnetic',
        component: () => import( /* webpackChunkName: "about" */ '@/views/oilwaterthird/magnet.vue')
    },
    {
        path: '/naturalfrac',
        name: 'naturalfrac',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/nature.vue')
    },    {
        path: '/geomechanics',
        name: 'geomechanics',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/research.vue')
    },
    {
        path: '/thinreservoir',
        name: 'thinreservoir',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/reservoir.vue')
    },
    {
        path: '/pressurereduction',
        name: 'pressurereduction',
        component: () => import( /* webpackChunkName: "about" */ '@/views/oilwaterthird/pressure.vue')
    },
    {
        path: '/T-type',
        name: 'T-type',
        component: () => import( /* webpackChunkName: "about" */ '@/views/specialthird/tdrill.vue')
    },
    {
        path: '/liquidembedded',
        name: 'liquidembedded',
        component: () => import( /* webpackChunkName: "about" */ '@/views/specialthird/embed.vue')
    }, {
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "about" */ '@/views/Login.vue')
    },
    {
        path: '/fineseismic',
        name: 'fineseismic',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/earthquake.vue')
    },
    {
        path: '/welltrajectory',
        name: 'welltrajectory',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/welltrack.vue')
    },
    {
        path: '/exploration',
        name: 'exploration',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/exploration.vue')
    },
    {
        path: '/geoengineering',
        name: 'geoengineering',
        component: () => import( /* webpackChunkName: "about" */ '@/views/study/geologicalEngineering.vue')
    },
    {
        path: '/potentialtapping',
        name: 'potentialtapping',
        meta: {
            nameEn: '地质工程相结合老井挖潜系列技术',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/ground/connect.vue')
    },
    {
        path: '/info',
        name: 'info',
        meta: {
            nameEn: '个人信息',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/info.vue'),
        children: [{
            path: '/userInformation',
            name: 'userInformation',
            component: () => import('@/views/userLog/userInformation.vue'), // 个人信息
            meta: {
                requireAuth: true,
                needRefresh: true, // 需要刷新
                nameEn: ' 个人信息',
            },
        }, {
            path: '/secure',
            name: 'secure',
            component: () => import('@/views/userLog/secure.vue'), // 个人信息
            meta: {
                requireAuth: true,
                needRefresh: true, // 需要刷新
                nameEn: ' 账户安全',
            },
        },
            {
                path: '/changePhone',
                name: 'ChangePhone',
                component: () => import('@/views/userLog/ChangePhone.vue'), // 修改手机号
                meta: {
                    requireAuth: true,
                    nameEn:'修改手机号',
                },
            },
            {
                path: '/changePassword',
                name: 'ChangePassword',
                component: () => import('@/views/userLog/ChangePassword.vue'), // 修改密码
                meta: {
                    requireAuth: true,
                    nameEn:'修改密码',
                },
            },
            {
                path: '/changeEmail',
                name: 'ChangeEmail',
                component: () => import('@/views/userLog/ChangeEmail.vue'), // 修改密码
                meta: {
                    requireAuth: true,
                    nameEn:'修改密码',
                },
            },]
    },
    {
        path: '/infoMobile',
        name: 'infoMobile',
        meta: {
            nameEn: '个人信息',
        },
        component: () => import( /* webpackChunkName: "about" */ '@/views/infoMobile.vue'),
        children: [
          ]
    },
    {
        path: '/userInfoMobile',
        name: 'userInfoMobile',
        component: () => import('@/views/userLog/userInfoMobile.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 个人信息',
        },
    },
    {
        path: '/secureMobile',
        name: 'secureMobile',
        component: () => import('@/views/userLog/secureMobile.vue'), // 个人信息
        meta: {
            requireAuth: true,
            needRefresh: true, // 需要刷新
            nameEn: ' 账户安全',
        },
    },
    {
        path: '/changePhone1',
        name: 'ChangePhone1',
        component: () => import('@/views/userLog/ChangePhone1.vue'), // 修改手机号
        meta: {
            requireAuth: true,
            nameEn:'修改手机号',
        },
    },
    {
        path: '/changePassword1',
        name: 'ChangePassword1',
        component: () => import('@/views/userLog/ChangePassword1.vue'), // 修改密码
        meta: {
            requireAuth: true,
            nameEn:'修改密码',
        },
    },
    {
        path: '/changeEmail1',
        name: 'ChangeEmail1',
        component: () => import('@/views/userLog/ChangeEmail1.vue'), // 修改密码
        meta: {
            requireAuth: true,
            nameEn:'修改密码',
        },
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // console.log("tooto",from)
    // console.log(to.path === '/ExternalLinkHandler')
    if (to.path === '/ExternalLinkHandler') {
        // 如果是从 ExternalLinkHandler 页面返回，恢复滚动位置
        router.back()
    }

    next();
});
export default router
