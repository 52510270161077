<template>
  <div>
    <div v-if="model===false">
      <div ref="total" class="total">
        <div class="screen1">
          <video id="myVideo" ref="myVideo" autoplay height="100%"
                 loop muted playsinline style="object-fit: cover;position:absolute;top:0;left:0; z-index: -1"
                 width="100%">
            <source src="@/video/bannerv3.mp4" type="video/mp4">
          </video>
          <div class="topback">
            <headtip @showDialog="showPartner" @showskip="scrollskip"></headtip>
          </div>

          <!--      logo  you气通GPT社区 合作伙伴  登录-->
          <div v-if="scrollTrue===false" class="up" @click="uptop"></div>
          <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
          <div class="layer2" style="z-index: 999999999999">
            <div class="title1">地质工程云平台GPTs
              <!--              <div class="title2">人员跨地域、跨专业实时联动、高效协同工作，工程设计方案、工程优化方案实时输出的人工智能云平台</div>-->
            </div>

            <div class="title31" @click="position2">
              <div class="hoverImg"><img src="@/assets/img01.png"/></div>
              <div class="hoverTitleup">GEGPTs</div>
              <div class="hoverTitleup2">免费为用户提供在线的油藏地质、开发工程基于AI大模型技术的应用模型，通过GPT辅助地质分析及工程优化。
              </div>
            </div>
            <div class="title32" @click="position6">
              <div class="hoverImg"><img src="@/assets/img02.png"/></div>
              <div class="hoverTitleup">全球在线专家支持服务</div>
              <div class="hoverTitleup2"> 联通全球服务网点及后台技术人员，为用户提供算力、技术力量、油藏地质信息、项目信息及时互通的一体化共享云服务。
              </div>
            </div>
            <div class="title33" @click="position7">
              <div class="hoverImg"><img src="@/assets/img03.png"/></div>
              <div class="hoverTitleup">油气田开发系列技术服务</div>
              <div class="hoverTitleup2"> 全球油气开发客户面向未来可持续发展，安东提供全方位解决方案。
              </div>
            </div>
          </div>
          <!--      平台大图-->
          <div class="layer3"></div>
        </div>
        <!--       油藏地质精细研究服务-->
        <div ref="generalMove" class="screenConsult" style="background-color:white">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;GEGPTs</div>
            </div>
          </div>
          <div class="light" style="text-align: center">
            免费为用户提供在线的油藏地质、开发工程基于AI大模型技术的应用模型，通过GPT辅助地质分析及工程优化。

          </div>
          <div class="pro2">

            <div class="swiper-containerpc">
              <div class="swiper-wrapper">
                <div class="swiper-slide" @click="recog">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome1.png">
                        <div class="photohover"></div>
                        <div class="photolist">01</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 智能油藏地质解释GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="fibernew">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome2.png">
                        <div class="photohover"></div>
                        <div class="photolist">02</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 光纤漏点解释GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="eur">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome3.png">
                        <div class="photohover"></div>
                        <div class="photolist">03</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">压裂方案参数设计优化GPTs</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="plug">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome4.png">
                        <div class="photohover"></div>
                        <div class="photolist">04</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">智能砂堵预警GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>

        <!--       油藏地质精细研究服务-->
        <div ref="consultMove" class="screenGpts">
          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title">&nbsp;全球在线专家支持服务</div>
            </div>
          </div>
          <div class="light" style="text-align: center">
            联通全球服务网点及后台技术人员，为用户提供算力、技术力量、油藏地质信息、项目信息及时互通的一体化共享云服务。
            <!--            <span class="light2"><br>如想基于开放的通用性GPTs开展定制化的使用培训和开发，请联系我们，<span  @click="clickConsult('油藏地质精细研究服务')" style="color: #2F6EFF;cursor: pointer">点击咨询</span>。</span>-->
            <br> <span
              style="cursor:pointer;text-align: center;color: #15257b;margin: 0 auto 2vh auto;font-size: 1vw;border-bottom:#15257b 1px solid;width: fit-content; "
              @click="login1">点击登录</span>

          </div>

          <div>

            <div style="width: 80vw;height:60vh;margin:0 auto;position: relative">
              <dv-flyline-chart :config="config" :dev="true" style="position:absolute;;width:80vw;height:60vh;"/>
              <dv-flyline-chart :config="config2" :dev="true" style="position:absolute;width:80vw;height:60vh;"/>

              <dv-flyline-chart :config="config3" :dev="true" style="position:absolute;width:80vw;height:60vh;"/>
              <dv-flyline-chart :config="config4" :dev="true" style="position:absolute;width:80vw;height:60vh;"/>
              <!--              <img src="../assets/second.png">-->
            </div>
          </div>

        </div>
        <!--       油藏地质精细研究服务-->
        <div ref="professional" class="screenConsultnew" style="background-color: white">

          <div style="display: flex;margin:0 auto;">
            <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
              <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
              <!--              </div>-->
              <div class="title1">&nbsp;油气田开发系列技术服务</div>
            </div>
          </div>
          <div class="light1" style="text-align: center">
            全球油气开发客户面向未来可持续发展，安东提供全方位解决方案！
          </div>

          <div class="serviceStyle">

            <div class="swiper-containerpc">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="box1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/new1.png">
                      </div>
                      <div class="photolist">
                        <img src="@/assets/newicon1.png">
                      </div>
                    </div>
                    <div class="title" style="color: #193871">
                      油藏地质精细研究服务
                    </div>
                    <div class="light" style="color: #193871">
                      <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti1.png"/>油气藏综合地质评价服务
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti2.png"/>油气藏整体开发方案设计服务
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti3.png"/>油气藏研究特色技术
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="box1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/new2.png">
                      </div>
                      <div class="photolist">
                        <img src="@/assets/newicon2.png">
                      </div>
                    </div>
                    <div class="title" style="color: #193871">
                      工程地质技术服务
                    </div>
                    <div class="light" style="color: #193871">
                      <div class="lightbehind" @click="intelligent"><img class="biaoti" src="@/assets/biaoti4.png"/>智能工程迭代优化GPTs
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <div class="lightbehind" @click="connect"><img class="biaoti" src="@/assets/biaoti5.png"/>地质工程相结合老井挖潜系列技术
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="box1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/new3.png">
                      </div>
                      <div class="photolist">
                        <img src="@/assets/newicon3.png">
                      </div>
                    </div>
                    <div class="title" style="color: #193871">
                      油气增产技术服务
                    </div>
                    <div class="light" style="color: #193871">
                      <div class="lightbehind" @click="single"><img class="biaoti" src="@/assets/biaoti6.png"/>一体化增产技术服务及配套技术
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <div class="lightbehind" @click="integration"><img class="biaoti" src="@/assets/biaoti7.png"/>特色增产产品
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <!--              <div @click="risk" class="lightbehind"><img class="biaoti"  src="@/assets/biaoti8.png"/>增产措施效果监测评估技术               <div class="iconStyle"><i style="color: blue" class="el-icon-arrow-right"></i></div></div>-->
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="box1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/new4.png">
                      </div>
                      <div class="photolist">
                        <img src="@/assets/newicon4.png">
                      </div>
                    </div>
                    <div class="title" style="color: #193871">
                      油气田开发动态监测服务
                    </div>
                    <div class="light" style="color: #193871">
                      <div class="lightbehind" @click="distributevue"><img class="biaoti" src="@/assets/biaoti10.png"/>分布式光纤实时监测技术
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <div class="lightbehind" @click="gasreservoir"><img class="biaoti" src="@/assets/biaoti11.png"/>油气藏测井技术
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                      <div class="lightbehind" @click="number"><img class="biaoti" src="@/assets/biaoti12.png"/>数字岩石岩屑
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="box1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/new5.png">
                      </div>
                      <div class="photolist">
                        <img src="@/assets/newicon5.png">
                      </div>
                    </div>
                    <div class="title" style="color: #193871">
                      增产整体解决方案
                    </div>
                    <div class="light" style="color: #193871">
                      <div class="lightbehind" @click="increase"><img class="biaoti" src="@/assets/biaoti9.png"/>增产整体解决方案
                        <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>


          <!--          <div class="servicegrand" >-->
          <!--            <div class="servicegrandback">-->
          <!--            <div class="title">油藏地质精细研究服务</div>-->
          <!--            <div class="light" >-->
          <!--              聚焦单井落地精细研究，以地质工程相结合为特色研究，为客户提供全面地质研究评价、开发方案编制服务，同时为安东整体解决方案-->
          <!--提供地质风险服务，<span  style="color: rgb(245,114,21);cursor: pointer" @click="clickConsult('油藏地质精细研究服务')">点击咨询</span>。-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          </div>-->
          <!--          <div class="servicecontent" >-->
          <!--            <div class="contentcard" @click="comment" >-->
          <!--              <div class="servicecontenttitle" style="color: black;text-align: center" >-->
          <!--                油气藏综合地质评价服务-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard" @click="exploit">-->
          <!--              <div class="servicecontenttitle" >-->
          <!--                油气藏整体开发方案设计服务-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard" @click="forecast">-->
          <!--              <div class="servicecontenttitle">-->
          <!--                油气藏研究特色技术-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </div>-->

          <!--          <div class="servicegrand">-->
          <!--            <div class="servicegrandback" >-->
          <!--              <div class="title">工程地质技术服务</div>-->
          <!--              <div class="light">-->
          <!--                以油气藏地质研究、地球物理勘探、地质力学建模、甜点预测及井轨迹优化、钻井压裂过程实时迭代优化、油藏精细描述及剩余油分析、-->
          <!--老井挖潜为核心的地质工程技术系列，涵盖油气田勘探开发研究全过程，<span  style="color: rgb(245,114,21);cursor: pointer" @click="clickConsult('  工程地质技术服务')">点击咨询</span>。-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="servicecontent" >-->
          <!--            <div class="contentcard" @click="intelligent">-->
          <!--              <div class="servicecontenttitle" style="color: black;text-align: center">-->
          <!--                智能工程迭代优化GPTs-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard" @click="connect">-->
          <!--              <div class="servicecontenttitle" >-->
          <!--                地质工程相结合老井挖潜系列技术-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="servicegrand" >-->
          <!--            <div class="servicegrandback" >-->
          <!--              <div class="title" >油气增产技术服务</div>-->
          <!--              <div class="light" >-->
          <!--                从储层评估、设计方案，到现场实施、压后评估、生产监测一站式、全方位的增产技术服务能力，致力于油气领域增产关键技术研究及-->
          <!--工业化应用，为油气田储层改造难题提供综合解决方案，-->
          <!--                <span style="color: rgb(245,114,21);cursor: pointer"  @click="clickConsult('油气增产技术服务')" >点击咨询</span>。-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="servicecontent" >-->
          <!--            <div class="contentcard" @click="single" >-->
          <!--              <div class="servicecontenttitle" style="color: black;text-align: center">-->
          <!--                一体化增产技术服务及配套技术-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard" @click="integration">-->
          <!--              <div class="servicecontenttitle" >-->
          <!--                特色增产产品-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard" @click="risk">-->
          <!--              <div class="servicecontenttitle">-->
          <!--                增产措施效果监测评估技术-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </div>-->

          <!--          <div class="servicegrand" >-->
          <!--            <div class="servicegrandback" >-->
          <!--              <div class="title" >油气田开发动态监测服务</div>-->
          <!--              <div class="light">-->
          <!--                以光纤为特色，提供覆盖钻井、完井、生产油气开发全过程油藏地质评价及开发工程监测服务，便于油气开发过程中的精细评价及精准-->
          <!--工程控制，<span  style="color: rgb(245,114,21);cursor: pointer" @click="clickConsult('油藏地质精细研究服务')">点击咨询</span>。-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="servicecontent" >-->
          <!--            <div class="contentcard" @click="distributevue">-->
          <!--              <div class="servicecontenttitle" >-->
          <!--                分布式光纤实时监测技术-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard" @click="gasreservoir">-->
          <!--              <div class="servicecontenttitle" >-->
          <!--                油气藏测井技术-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="contentcard"  @click="number">-->
          <!--              <div class="servicecontenttitle">-->
          <!--                数字岩石岩屑-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </div>-->

          <!--          <div class="servicegrand" style="background-color: #123a74;width: 80vw;height: fit-content;margin: 4vh 0  0">-->
          <!--            <div style="padding: 4vh 4vh 0vh 4vh">-->
          <!--              <div class="title" style="color:white;margin: 0;height: unset">增产整体解决方案</div>-->
          <!--              <div class="light" style="width: fit-content;color: white;margin: unset;padding: 1vh 0 1vh 0">-->
          <!--                致力于与客户联合创新，通过地质工程紧密结合，精准工程技术，精益作业管理，为客户提供一体化整体解决方案 ，依靠成熟的项目管理经验，先进适用的技术组合，从价格竞争上升到价值创造，通过创新，为客户创造突破性增产提效贡献，-->
          <!--                <span   style="color: rgb(245,114,21);;cursor: pointer" @click="clickConsult('增产整体解决方案')">点击咨询</span>。-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="servicecontent" style="display: grid;grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  minmax(0, 1fr)   ;height: fit-content; background:linear-gradient(#123a74 50%, white 50%);">-->
          <!--            <div class="contentcard" @click="increase" style="height: fit-content;width: 16vw;margin-left:0.5vw;background-color: #95d1d1;font-size: 1.0vw;padding: 2vh 2vw 2vh 2vw;font-weight: bolder;" >-->
          <!--              <div class="servicecontenttitle" style="color: black;text-align: center">-->
          <!--                增产整体解决方案-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->


        </div>
        <!--        &lt;!&ndash;       油藏地质精细研究服务&ndash;&gt;-->
        <!--底部-->
        <div class="screen7">
          <bottomTip @showComment="showComment" @showDialogBottom="scroll" @showNet="showNet"
                     @showProduct="showProduct"></bottomTip>
        </div>
        <netFeedback :dialogVisible="dialogNet"
                     @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
    <div v-else>
      <div id="totalModel" ref="totalModel" class="totalModel">
        <div class="screen1">
          <!--          <video id="myVideo" ref="myVideo" width="100%" height="340px" style="object-fit: cover;position:absolute;top:0;left:0; z-index: 0" autoplay loop muted playsinline>-->
          <!--            <source src="@/video/bannerv3.mp4" type="video/mp4">-->
          <!--          </video>-->

          <headOther @showDialog="showPartner" @showDialogTop="scroll"></headOther>
          <!--      logo  you气通GPT社区 合作伙伴  登录-->
        </div>
        <div v-if="scrollTrue===false" class="up" @click="uptop"></div>


        <div ref="GEGPTs" class="GEGPTs" style="background-color:white">
          <div class="title" style="color: #193871">
            GEGPTs
          </div>
          <div class="light" style="color: #193871">
            免费为用户提供在线的油藏地质、开发工程人工智能应用模型。
          </div>

          <div class="pro2">
            <div class="swiper-container0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" @click="recog">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome1.png">
                        <div class="photolist">01</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 智能油藏地质解释GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="fibernew">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome2.png">
                        <div class="photolist">02</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 光纤漏点解释GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="eur">
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome3.png">
                        <div class="photolist">03</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1">压裂方案参数设计优化GPTs</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" @click="plug">
                  <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                  <div class="grand1">
                    <div class="back">
                      <div class="photo">
                        <img src="@/assets/groundHome4.png">
                        <div class="photolist">04</div>
                      </div>
                    </div>
                    <div class="back1">
                      <div class="title1"> 智能砂堵预警GPTS</div>
                      <div class="title2">
                        了解更多

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>

        <div ref="map" class="map" style=";">
          <div style="background-color: #bed6f5;">
            <div class="title" style="color: #193871">
              全球在线专家支持服务
            </div>
            <div class="light" style="color: #193871">
              联通全球服务网点及后台技术人员的一体化共享云服务。
            </div>
            <div class="light"
                 style="color: #193871;margin: -5px auto 15px auto;width: fit-content;font-size: 14px;border-bottom: 1px solid #193871"
                 @click="login">
              点击登录
            </div>
          </div>
          <div style="position: relative;height: 170px;width: 350px">
            <dv-flyline-chart :config="configmodel" :dev="true" style="position:absolute;;height: 150px;width: 350px;"/>
            <dv-flyline-chart :config="configmodel2" :dev="true" style="position:absolute;height: 150px;width: 350px;"/>

            <dv-flyline-chart :config="configmodel3" :dev="true" style="position:absolute;height: 150px;width: 350px"/>
            <dv-flyline-chart :config="configmodel4" :dev="true" style="position:absolute;height: 150px;width: 350px;"/>
            <!--              <img src="../assets/second.png">-->
          </div>
<!--          <img src="../assets/second.png" width="100%">-->
        </div>

        <div ref="seriesService" class="seriesService" style="padding: 0 0 10px 0">
          <div class="title" style="color: #193871">
            油气田开发系列技术服务
          </div>
          <div class="light" style="color: #193871">
            全球油气开发客户面向未来可持续发展，安东提供全方位解决方案！
          </div>

          <div class="swiper-container1">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new1.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon1.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    油藏地质精细研究服务
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="comment"><img class="biaoti" src="@/assets/biaoti1.png"/>油气藏综合地质评价服务
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <div class="lightbehind" @click="exploit"><img class="biaoti" src="@/assets/biaoti2.png"/>油气藏整体开发方案设计服务
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <div class="lightbehind" @click="forecast"><img class="biaoti" src="@/assets/biaoti3.png"/>油气藏研究特色技术
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new2.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon2.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    工程地质技术服务
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="intelligent"><img class="biaoti" src="@/assets/biaoti4.png"/>智能工程迭代优化GPTs
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <div class="lightbehind" @click="connect"><img class="biaoti" src="@/assets/biaoti5.png"/>地质工程相结合老井挖潜系列技术
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new3.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon3.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    油气增产技术服务
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="single"><img class="biaoti" src="@/assets/biaoti6.png"/>一体化增产技术服务及配套技术
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <div class="lightbehind" @click="integration"><img class="biaoti" src="@/assets/biaoti7.png"/>特色增产产品
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <!--              <div @click="risk" class="lightbehind"><img class="biaoti"  src="@/assets/biaoti8.png"/>增产措施效果监测评估技术               <div class="iconStyle"><i style="color: blue" class="el-icon-arrow-right"></i></div></div>-->
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new4.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon4.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    油气田开发动态监测服务
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="distributevue"><img class="biaoti" src="@/assets/biaoti10.png"/>分布式光纤实时监测技术
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <div class="lightbehind" @click="gasreservoir"><img class="biaoti" src="@/assets/biaoti11.png"/>油气藏测井技术
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                    <div class="lightbehind" @click="number"><img class="biaoti" src="@/assets/biaoti12.png"/>数字岩石岩屑
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="box1">
                  <div class="back">
                    <div class="photo">
                      <img src="@/assets/new5.png">
                    </div>
                    <div class="photolist">
                      <img src="@/assets/newicon5.png">
                    </div>
                  </div>
                  <div class="title" style="color: #193871">
                    增产整体解决方案
                  </div>
                  <div class="light" style="color: #193871">
                    <div class="lightbehind" @click="increase"><img class="biaoti" src="@/assets/biaoti9.png"/>增产整体解决方案
                      <div class="iconStyle"><i class="el-icon-arrow-right" style="color: blue"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>

        </div>


        <!--底部-->
        <div ref="bottom" class="screen7">
          <bottomTip @showDialogBottom="scroll"></bottomTip>
        </div>

        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
      </div>
    </div>
  </div>
</template>
<script>
import PartnerTip from "@/components/partnerTip.vue";
import headtip from "@/views/headTip.vue";
import headOther from "@/views/headOther.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import {distribute} from "gsap/gsap-core";
import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'

export default {
  name: "Home",
  components: {
    bottomTip,
    PartnerTip,
    consultTip,
    headtip,
    headOther,
    commentFeedback,
    productFeedback,
    netFeedback
  },
  data() {
    return {
      config: {
        centerPoint: [0.56, 0.52],
        points: [
          {
            position: [0.28, 0.68],
            text: '作业现场',
          },
          {
            position: [0.25, 0.17],
            text: '作业现场 ',
          },
          {
            position: [0.56, 0.74],
            text: '作业现场',
          },

        ],
        k: -0.5,
        lineWidth:2,
        flylineColor: ' #132279',
        flylineRadius:350,
        duration:[20,30],
        orbitColor: '',
        bgImgUrl: require("../assets/second.png"),
        centerPointImg: {
          url: require("../assets/center.png"),
        },curvature:5,
        pointsImg: {
          width: 40,
          height: 40,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,35],
          fontSize:18,
          color:'#13237a'
        }
      },
      config2: {
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.63, 0.18],
            text: '作业现场',
          },
          {
            position: [0.65, 0.40],
            text: '作业现场',
          },
        ],
        k: -0.5,
        flylineColor: '#132279',
        flylineRadius:450,
        lineWidth:2,
        orbitColor: '',
        curvature:3,
        bgImgUrl: '',
        centerPointImg: {
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 40,
          height: 40,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,35],
          fontSize:18,
          color:'#13237a'
        }
      },
      config3: {
        centerPoint: [0.81, 0.36],
        points: [


     {
            position: [0.90, 0.23],
            text: '作业现场',
          }, {
            position: [0.93, 0.79],
            text: '作业现场',
          },

        ],
        k: -1,
        flylineColor: '#132279 ',
        flylineRadius:550,
        lineWidth:2,
        orbitColor: '',
        curvature:3.5,
        bgImgUrl: '',
        centerPointImg: {
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 40,
          height: 40,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,35],
          fontSize:18,
          color:'#13237a'
        }
      },
      config4: {
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.56, 0.52],
          },
          {
            position:  [0.81, 0.36],
          },
        ],
        k: -0.5,
        flylineColor: ' #132279',
        flylineRadius:350,
        lineWidth:2,
        orbitColor: '',
        curvature:4,
        bgImgUrl: '',
        centerPointImg: {
          url: '',
        },
        pointsImg: {
          width: 40,
          height: 40,
          url: '',
        },
        text:{
          offset:[0,35],
          fontSize:18,
          color:'#13237a'
        }
      },
      configmodel: {
        halo:{
          show:true,
          radius:50,
        },
        centerPoint: [0.56, 0.52],
        points: [
          {
            position: [0.28, 0.68],
            text: ' ',
          },
          {
            position: [0.25, 0.17],
            text: '  ',
          },
          {
            position: [0.56, 0.74],
            text: ' ',
          },

        ],
        k: -0.5,
        lineWidth:1,
        flylineColor: ' ',
        flylineRadius:50,
        duration:[20,30],
        orbitColor: '',
        bgImgUrl: require("../assets/second.png"),
        centerPointImg: {
          width: 20,
          height: 20,
          url: require("../assets/center.png"),
        },curvature:5,
        pointsImg: {
          width: 15,
          height: 15,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,18],
          fontSize:10,
          color:'#13237a'
        }
      },
      configmodel2: {
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.59, 0.10],
            text: ' ',
          },
          {
            position: [0.65, 0.40],
            text: ' ',
          },
        ],
        halo:{
          radius:50
        },
        k: -0.5,
        flylineColor: ' ',
        flylineRadius:50,
        lineWidth:1,
        orbitColor: '',
        curvature:3,
        bgImgUrl: '',
        centerPointImg: {
          width: 20,
          height: 20,
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 15,
          height: 15,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,18],
          fontSize:10,
          color:'#13237a'
        }
      },
      configmodel3: {
        centerPoint: [0.81, 0.36],
        halo:{
          radius:50
        },
        points: [


          {
            position: [0.90, 0.23],
            text: ' ',
          }, {
            position: [0.93, 0.79],
            text: ' ',
          },

        ],
        k: -1,
        flylineColor: '  ',
        flylineRadius:50,
        lineWidth:1,
        orbitColor: '',
        curvature:3.5,
        bgImgUrl: '',
        centerPointImg: {
          width: 20,
          height: 20,
          url: require("../assets/center.png"),
        },
        pointsImg: {
          width: 15,
          height: 15,
          url: require("../assets/assets.png"),
        },
        text:{
          offset:[0,18],
          fontSize:10,
          color:'#13237a'
        }
      },
      configmodel4: {
        halo:{
          show:false
        },
        centerPoint: [0.61, 0.34],
        points: [
          {
            position: [0.56, 0.52],
          },
          {
            position:  [0.81, 0.36],
          },
        ],
        k: -0.5,
        flylineColor: ' ',
        flylineRadius:50,
        lineWidth:1,
        orbitColor: '',
        curvature:4,
        bgImgUrl: '',
        centerPointImg: {
          url: '',
        },
        pointsImg: {
          width: 20,
          height: 20,
          url: '',
        },
        text:{
          offset:[0,35],
          fontSize:10,
          color:'#13237a'
        }
      },
      scrollPosition: null,
      model: false,
      swiper: null,
      avator: require("../assets/user.jpg"),
      userName: "",
      userId: null,
      dialogVisible: false,
      dialogComment: false,
      dialogProduct: false,
      dialogNet: false,
      title: "数智油田",
      dialogconsult: false,
      product: 1,
      infogpt: 1,
      currentIndex: 0,
      itemsPerPage: 4,
      letters: '0123456789ABCDEF',
      carouselItems: [
        {
          img: require("../assets/logo1.png"),
          title: "智能钻井",
          text:
              "着陆段地质层分类预警：基于六个维度数据特征，建立最小AI模型，准确率提升20%。" +
              "<br>" +
              "水平段上下穿层预警：水平段穿层实时判断、上下切角实时预警建立最小模型。",
        },
        {
          img: require("../assets/logo2.png"),
          title: "智能压裂",
          text:
              "影响压裂效果的指标权重分析：影响压裂效果（EUR）的多维地质工程参数的指标权重分析。\n" +
              "<br>" +
              "压裂产量EUR预测：基于给定的多维地质工程参数，对EUR的AI预测模型准确率78%。",
        },
        {
          img: require("../assets/logo3.png"),
          title: "智能采油气",
          text: "磕头机的生产冲次辅助决策：构建智能采油气机器人提供辅助决策。",
        },
        {
          img: require("../assets/logo4.png"),
          title: "地层监测",
          text: "基于光纤的井筒完整性监测：基于光纤DTS温度数据建立对井筒故障监测及位置预警的最小模型。",
        },
        {
          img: require("../assets/logo5.png"),
          title: "设备设施监测",
          text: "设备故障检测：基于开源的滚动轴承数据建立模型，实时判断轴承故障类型、故障大小、故障位置",
        },
      ],
      groupedItems: [],
      scrollY: 0,
      scrollHeight: 0,
      scrollTrue: true,
    };
  },

  computed: {
    // unconventional() {
    //   return unconventional
    // }
    // network() {
    //   return network
    // },
    // technology() {
    //   return technology
    // },

  },
  created() {
    localStorage.setItem("home", 1)
    // 通用GPT
    if (sessionStorage.getItem("ground") === '1') {
      this.$nextTick(() => {
        this.position2();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("ground") === '2') {
      this.$nextTick(() => {
        this.position7();
      });
    }
    // 底部
    else if (sessionStorage.getItem("ground") === '3') {
      this.$nextTick(() => {
        this.position6();
      });
    }
    // 油气专业GPTs
    else if (sessionStorage.getItem("ground") === '4') {
      this.$nextTick(() => {
        this.position3();
      });
    } else if (sessionStorage.getItem("ground") === '6') {
      this.$nextTick(() => {
        this.posHandle('generalAuto')
      });
    } else if (sessionStorage.getItem("ground") === '0') {
      this.$nextTick(() => {
        this.uptop();
      });
    }


  },

  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.updateModel();
    this.initSwiper();
    const videoEl = this.$refs.myVideo;
    videoEl.play();
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    // 通用GPT
    if (sessionStorage.getItem("skip") === '1') {
      this.$nextTick(() => {
        this.skipp1();
      });
    }
    // 油气资讯
    else if (sessionStorage.getItem("skip") === '2') {
      this.$nextTick(() => {
        this.skipp2();
      });
    }
    // 底部
    else if (sessionStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
        // 油气专业GPTs
        // else if (sessionStorage.getItem("skip") === '4') {
        //   this.$nextTick(() => {
        //     this.skipp4();
        //   });
        // }
    // 油气专业GPTs
    else if (sessionStorage.getItem("skip") === '5') {
      this.$nextTick(() => {
        this.skipp5();
      });
    }
    // 油气开发作业GPT应用服务
    else if (sessionStorage.getItem("skip") === '6') {
      this.$nextTick(() => {
        this.skipp6();
      });
    } else if (sessionStorage.getItem("skip") === '7') {
      this.$nextTick(() => {
        this.skipp7();
      });
    } else if (sessionStorage.getItem("skip") === '8') {
      this.$nextTick(() => {
        this.skipp8();
      });
    } else if (sessionStorage.getItem("skip") === '10') {
      this.$nextTick(() => {
        this.skipp10();
      });
    } else if (sessionStorage.getItem("skip") === '11') {
      this.$nextTick(() => {
        this.skipp11();
      });
    } else if (sessionStorage.getItem("skip") === '12') {
      this.$nextTick(() => {
        this.skipp12();
      });
    } else if (sessionStorage.getItem("skip") === '13') {
      this.$nextTick(() => {
        this.skipp13();
      });
    } else if (sessionStorage.getItem("skip") === '14') {
      this.$nextTick(() => {
        this.skipp14();
      });
    }
    // 添加滚动事件监听器
    this.updateGroupedItems();
  },

  methods: {
    updateModel() {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.model = screenWidth <= 700;
    },
    initSwiper() {
      // 确保 swiper-container 已经被渲染
      this.$nextTick(() => {

        this.swiper = new Swiper(".swiper-containerpc", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 3,
          spaceBetween: 15,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });
        this.swiper = new Swiper(".swiper-container0", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });

        this.swiper = new Swiper(".swiper-container1", {
          autoplay: 3000,
          disableOnInteraction: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          loop: false,
          pagination: ".swiper-pagination",
          paginationType: "bullets",
          paginationClickable: true,
          pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放
        });

      });
    },
    login() {
      this.$router.push("/login")
    },
    login1() {
      window.open("/login")
    },
    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct() {
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet() {
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },

    fibernew() {
      window.open("fibernew")
    },
    eur() {
      window.open("eur")
    },
    plug() {
      window.open("plug")
    },
    recog() {
      window.open("recognition")
    },
    posHandle(ref) {
      let element = this.$refs[ref];
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      document.querySelector('.total').scroll({
        top: rect,
        behavior: 'smooth'
      })
    },
    distribute() {
      return distribute
    },

    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
    },

    clickConsult(item) {
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    show() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    comment() {
      window.open("/geoevaluation", "_blank");
    },
    exploit() {
      window.open("/schemedesign", "_blank");
    },
    intelligent() {
      window.open("/intelligentengineering", "_blank");
    },
    connect() {
      window.open("/potentialtapping", "_blank");
    },
    special() {
      window.open("/specialengineering", "_blank");
    },
    forecast() {
      window.open("/featuretech", "_blank");
    },
    ground() {
      window.open("/ground", "_blank");
    },
    modeling() {
      window.open("/modeling", "_blank");
    },
    single() {
      window.open("/integratedstimulation", "_blank");
    },
    integration() {
      window.open("/featuredproducts", "_blank");
    },
    risk() {
      window.open("/stimulationmonitoring", "_blank");
    },
    unconventional() {
      window.open("/unconventional", "_blank");
    },
    drill() {
      window.open("/drill", "_blank");
    },
    realtime() {
      window.open("/realtime", "_blank");
    },
    area() {
      window.open("/area", "_blank");
    },
    unite() {
      window.open("/unite", "_blank");
    },
    scene() {
      window.open("/scene", "_blank");
    },
    increase() {
      window.open("/TurnkeySolution", "_blank");
    },
    distributevue() {
      window.open("/FiberOptic", "_blank");
    },
    gasreservoir() {
      window.open("/logging", "_blank");
    },
    number() {
      window.open("/digital", "_blank");
    },


    gpts() {
      localStorage.setItem("skip", 0)
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    // ground() {
    //   localStorage.setItem("skip", 0)
    //   window.open("/groundOil", "_blank");
    // },
    oilgpt() {
      localStorage.setItem("skip", 0)
      window.open("/gptOil", "_blank");
    },
    // 钻井
    hole() {
      localStorage.setItem("skip", 0)
      window.open("/makeHole", "_blank");
    },
    oilDesign() {
      localStorage.setItem("skip", 0)
      window.open("/gptDesign", "_blank");
    },
    oilTranslate() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    oilTechnology() {
      localStorage.setItem("skip", 0)
      window.open("/gptTechnology", "_blank");
    },
    oilgpt2() {
      localStorage.setItem("skip", 0)
      window.open("/gptTranslate", "_blank");
    },
    // 压裂
    frack() {
      localStorage.setItem("skip", 0)
      window.open("/frackOil", "_blank");
    },
    oilgpt3() {
      localStorage.setItem("skip", 0)
      window.open("/gptHole", "_blank");
    },
    // 采油
    getOil() {
      localStorage.setItem("skip", 0)
      window.open("/getOil", "_blank");
    },

    // <!--设备检测-->
    equipment() {
      localStorage.setItem("skip", 0)
      window.open("/monitorEqu", "_blank");
    },

    // 地层监测
    monitor() {
      localStorage.setItem("skip", 0)
      window.open("/monitorOil", "_blank");
    },

    // 油田管理
    menage() {
      localStorage.setItem("skip", 0)
      window.open("/menageOil", "_blank");
    },

    station() {
      localStorage.setItem("skip", 0)
      window.open("/station", "_blank");
    },
    security() {
      localStorage.setItem("skip", 0)
      window.open("/security", "_blank");
    },
    productANxun() {
      localStorage.setItem("skip", 0)
      window.open("/product", "_blank");
    },
    equipmentAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/equipmentAnxun", "_blank");
    },
    technologyAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/technology", "_blank");
    },
    monitorAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/monitorAnxun", "_blank");
    },
    networkAnxun() {
      localStorage.setItem("skip", 0)
      window.open("/network", "_blank");
    },
    basic() {
      localStorage.setItem("skip", 0)
      window.open("/basic", "_blank");
    },
    setting() {
      localStorage.setItem("skip", 0)
      window.open("/setting", "_blank");
    },
    traning() {
      const element = this.$refs.aiPeixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    governance() {
      window.open("/governance", "_blank");
    },
    analysis() {
      window.open("/analysis", "_blank");
    },
    traningAi() {
      window.open("/traning", "_blank");
    },

    consult() {
      window.open("/consult", "_blank");
    },
    ERP() {
      window.open("/ERP", "_blank");
    },
    java() {
      window.open("/java", "_blank");
    },
    web() {
      window.open("/web", "_blank");
    },
    service() {
      const element = this.$refs.oilZixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    meitiProduct() {
      window.open("http://www.oilmates-omm.com/h-col-106.html", "_blank");
    },
    meiti3D() {
      window.open("http://www.oilmates-omm.com/h-col-102.html", "_blank");
    },
    meitiDesign() {
      window.open("http://www.oilmates-omm.com/h-col-104.html", "_blank");
    },
    construct() {
      const element = this.$refs.companyZijian;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    constructPlat() {
      window.open("/construct", "_blank");
    },
    business() {
      window.open("/business", "_blank");
    },
    company() {
      window.open("/company", "_blank");
    },
    computingPower() {
      window.open("/computingPower", "_blank");
    },

    menageService() {
      const element = this.$refs.menageService;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    application() {
      window.open("/application", "_blank");
    },
    oilApplication() {
      window.open("/oilApplication", "_blank");
    },

    knowledge() {
      window.open("/gptKnowledge", "_blank");
    },
    data() {
      window.open("/gptData", "_blank");
    },
    wisdom() {
      window.open("/wisdom", "_blank");
    },
    plat() {
      window.open("/plat", "_blank");
    },
    design() {
      window.open("/design", "_blank");
    },
    automation() {
      window.open("/automation", "_blank");
    },

    traning1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/traning")
    },
    service1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/service")
    },
    construct1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/construct")
    },
    menageService1() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    application1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/application")
    },
    oilApplication1() {
      sessionStorage.setItem("skip", 11)
      this.$router.push("/oilApplication")
    },

    knowledge1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/gptKnowledge")
    },
    data1() {
      this.$router.push("/gptData")
      sessionStorage.setItem("skip", 10)
    },
    wisdom1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/wisdom");
    },
    plat1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/plat")
    },
    design1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/design")
    },
    automation1() {
      sessionStorage.setItem("skip", 10)
      this.$router.push("/automation")
    },

    gpts1() {
      window.open("https://www.oilgascommunity.com/recently?id=39", "_blank");
    },
    // 油藏地质
    ground1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/groundOil")
    },
    oilgpt1() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptOil")
    },
    // 钻井
    hole1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/makeHole")
    },

    oilgpt21() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptTranslate")
    },
    // 压裂
    frack1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/frackOil")
    },
    oilgpt31() {
      sessionStorage.setItem("skip", 5)
      this.$router.push("/gptHole")
    },
    // 采油
    getOil1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/getOil")
    },

    // <!--设备检测-->
    equipment1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorEqu")
    },

    // 地层监测
    monitor1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/monitorOil")
    },

    // 油田管理
    menage1() {
      sessionStorage.setItem("skip", 6)
      this.$router.push("/menageOil")
    },

    station1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/station")
    },
    security1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/security")
    },
    productANxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/product")
    },
    equipmentAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/equipmentAnxun")
    },
    technologyAnxun1() {
      sessionStorage.setItem("skip", 7)
      this.$router.push("/technology")
    },
    monitorAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/monitorAnxun")
    },
    networkAnxun1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/network")
    },
    basic1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/basic")
    },
    setting1() {
      sessionStorage.setItem("skip", 8)
      this.$router.push("/setting")
    },
    showPartner() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = true;
    },
    showComment() {
      this.dialogComment = true
      // console.log("chufa")
    },
    showProduct() {
      this.dialogProduct = true
    },
    showNet() {
      this.dialogNet = true
    },
    scroll(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          this.position1();
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          this.position2();
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 5) {
        this.$nextTick(() => {
          this.position5();
          // this.posHandle('generalAutoMobile')
        });
      } else if (value == 6) {
        this.$nextTick(() => {
          this.position6();
          // this.posHandle('generalAuto')
        });
      } else if (value == 7) {
        this.$nextTick(() => {
          this.position7();
          // this.posHandle('generalAuto')
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    scrollskip(value) {
      console.log(value)
      // 通用GPT
      if (value == 1) {
        this.$nextTick(() => {
          this.position2();
        });
      } else if (value == 2) {
        this.$nextTick(() => {
          this.position7();
        });
      } else if (value == 3) {
        this.$nextTick(() => {
          this.position6();
        });
      } else if (value == 4) {
        this.$nextTick(() => {
          this.position3();
        });
      } else if (value == 0) {
        this.$nextTick(() => {
          this.uptop();
        });
      }
    },
    hideDialog() {
      localStorage.setItem("skip", 0)
      this.dialogVisible = false;
    },

    hide() {
      localStorage.setItem("skip", 0)
      this.dialogconsult = false;
    },
    expo() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasmall.com/", "_blank");
    },
    job() {
      localStorage.setItem("skip", 0)
      window.open("https://oilgasjobai.com/", "_blank");
    },
    info() {
      localStorage.setItem("skip", 0)
      window.open("https://cn.oilgasinfoai.com/", "_blank");
    },
    wen() {
      localStorage.setItem("skip", 0)
      window.open("https://yiyan.baidu.com/", "_blank");
    },
    dou() {
      localStorage.setItem("skip", 0)
      window.open("https://www.doubao.com/", "_blank");
    },
    xun() {
      localStorage.setItem("skip", 0)
      window.open("https://xinghuo.xfyun.cn/", "_blank");
    },
    tong() {
      localStorage.setItem("skip", 0)
      window.open("https://tongyi.aliyun.com/qianwen/", "_blank");
    },
    zhi() {
      localStorage.setItem("skip", 0)
      window.open("https://chatglm.cn/", "_blank");
    },
    teng() {
      localStorage.setItem("skip", 0)
      window.open("https://hunyuan.tencent.com/", "_blank");
    },
    tian() {
      localStorage.setItem("skip", 0)
      window.open("https://home.tiangong.cn/", "_blank");
    },
    ge() {
      localStorage.setItem("skip", 0)
      window.open("https://yige.baidu.com/", "_blank");
    },
    wan() {
      localStorage.setItem("skip", 0)
      window.open("https://ai.kezhan365.com/", "_blank");
    },
    yi() {
      localStorage.setItem("skip", 0)
      window.open("https://aigc.yizhentv.com/", "_blank");
    },


    expo1() {
      sessionStorage.setItem("skip", 2)
      // 在 Vue 组件或路由导航守卫中使用 router.push() 导航到外部链接
      const externalLink = 'https://cn.oilgasmall.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    job1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://oilgasjobai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    info1() {
      sessionStorage.setItem("skip", 2)
      const externalLink = 'https://cn.oilgasinfoai.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    wen1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yiyan.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    traningAi1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/traning")
    },
    consult1() {
      sessionStorage.setItem("skip", 12)
      this.$router.push("/consult")
    },
    constructPlat1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/construct")

    },
    business1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/business")
    },
    company1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/company")
    },
    computingPower1() {
      sessionStorage.setItem("skip", 13)
      this.$router.push("/computingPower")
    },
    inter() {
      window.open("inter")
    },

    position2() {
      if (this.model === false) {
        const element = this.$refs.generalMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        console.log("1545")
        const element = this.$refs.GEGPTs;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position3() {
      if (this.model === false) {
        const element = this.$refs.aiPeixun;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.aiPeixunMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position5() {
      if (this.model === false) {
        const element = this.$refs.generalAuto;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.generalAutoMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);


        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }
    },
    position6() {
      if (this.model === false) {
        const element = this.$refs.consultMove;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.map;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    position7() {
      if (this.model === false) {
        const element = this.$refs.professional;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.seriesService;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    position8() {
      if (this.model === false) {
        const element = this.$refs.generalAuto;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.total, rect, 500); // 滚动持续时间为500毫秒
      } else {
        const element = this.$refs.professionalMobile;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        // eslint-disable-next-line no-inner-declarations
        function scrollToElement(element, to, duration) {
          if (duration <= 0) return;
          const difference = to - element.scrollTop;
          const perTick = difference / duration * 10;
          setTimeout(() => {
            element.scrollTop = element.scrollTop + perTick;
            if (element.scrollTop === to) return;
            scrollToElement(element, to, duration - 10);
          }, 10);
        }

        scrollToElement(this.$refs.totalModel, rect, 500); // 滚动持续时间为500毫秒
      }

    },
    skipp1() {
      const element = this.$refs.general;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp2() {
      const element = this.$refs.zixun;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp4() {
      const element = this.$refs.professional;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },
    skipp5() {
      const element = this.$refs.professional1;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp6() {
      const element = this.$refs.upload;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp7() {
      const element = this.$refs.station;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp8() {
      const element = this.$refs.technology;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp10() {
      const element = this.$refs.menageServices;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    uptop() {

      if (this.model === false) {
        const element = this.$refs.total;
        const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.total.scrollTop = rect
        });
      } else {
        const elementModel = this.$refs.totalModel;
        const rect = elementModel.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this.$nextTick(() => {
          this.$refs.totalModel.scrollTop = rect
        });
      }


    },
    skipp11() {
      const element = this.$refs.company;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp12() {
      const element = this.$refs.traningConsult;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp13() {
      const element = this.$refs.companyConduct;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    skipp14() {
      const element = this.$refs.media;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    dou1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://www.doubao.com/';
      this.$router.replace({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    xun1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://xinghuo.xfyun.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    tong1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://tongyi.aliyun.com/qianwen/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    zhi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://chatglm.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    teng1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://hunyuan.tencent.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    tian1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://home.tiangong.cn/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    ge1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://yige.baidu.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    wan1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://ai.kezhan365.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    yi1() {
      sessionStorage.setItem("skip", 1)
      const externalLink = 'https://aigc.yizhentv.com/';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    meitiProduct1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-106.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meiti3D1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-102.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    meitiDesign1() {
      sessionStorage.setItem("skip", 14)
      const externalLink = 'http://www.oilmates-omm.com/h-col-104.html';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    updateGroupedItems() {
      const startIndex = this.currentIndex;
      this.groupedItems = [];

      for (let i = 0; i < this.itemsPerPage; i++) {
        const group = this.carouselItems.slice(
            startIndex + i,
            startIndex + i + this.itemsPerPage
        );
        this.groupedItems.push(group);
      }
    },
    leftnext() {
      console.log(this.currentIndex);
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.updateGroupedItems();
      }
    },
    rightnext() {
      if (this.currentIndex < 1) {
        this.currentIndex++;
        this.updateGroupedItems();
      }
    },
    onCarouselChange(index) {
      this.currentIndex = index;
      this.updateGroupedItems();
    },
  },
};
</script>
<style>
@media (max-width: 757px) {
  .el-message-box {
    width: 300px !important;
    margin: 100px auto !important; /* 水平居中 */
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
    margin-top: 0;
  }

  .swiper-pagination {
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 5px;
    border-radius: 9px;
  }

  .swiper-pagination-bullet-active {
    background-color: rgb(47, 110, 255);
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
  margin-top: 2vh;
}

.swiper-pagination {
  position: relative;
}

.swiper-pagination-bullet {
  width: 25px;
  border-radius: 9px;
}

.swiper-pagination-bullet-active {
  background-color: rgb(47, 110, 255);
}
</style>
<style lang="scss" scoped>
.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 16px;
  line-height: 1.3;
  text-align: justify;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0 0 2.84301606922126vh 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;

    .topback {
      position: fixed;
      z-index: 9;
      width: 99vw;
      padding: 1vh 0;
      background-color: rgb(52, 48, 48, 0.3);
    }

    .up {
      cursor: pointer;
      position: fixed;
      top: 88%;
      right: 2%;
      padding: 5px;
      width: 2.5vw;
      height: 2.5vw;
      border-radius: 50%;
      //border: 5px solid #1295d9;
      background-image: url("../assets/up.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .layer2 {
      margin-top: 0vh;
      text-align: center;

      .title1 {
        transition: transform 0.3s ease-out;
        cursor: pointer;
        padding-top: 12vh;
        height: fit-content;
        font-size: 2.6vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;

        .title2 {
          display: none;
          cursor: pointer;
          height: 6vh;
          font-size: 1.3vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #13237a;
          line-height: 9vh;
        }
      }

      .title1:hover {
        transform: translateY(-5px);

        .title2 {
          display: block;
        }

      }

      .title4 {
        //position: absolute;
        //bottom:3vh;
        //text-align: center;
        //display: flex;
        //display: grid;
        //grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //column-gap: 3vw;
        ////left:15vw;
        //width: fit-content;
        //height: fit-content;
        //left:26%;
        //transform: translateX(-15%);
        //font-family: Sarial, sans-serif, "Microsoft Yahei";
        //font-weight: bold;
        //color: white;
      }

      .title31 {
        position: absolute;
        bottom: 2vh;
        left: 12vw;
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 3vw;
        font-size: 1.1vw;
        font-family: 'Arial', sans-serif, 'Microsoft YaHei';
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 1vh auto;
          text-align: center;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: justify;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title31:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }

      .title32 {
        position: absolute;
        bottom: 2vh;
        left: 49.5%;
        transform: translateX(-50%);
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 3vw;
        font-size: 1.1vw;
        font-family: 'Arial', sans-serif, 'Microsoft YaHei';
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 1vh auto;
          text-align: center;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: justify;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title32:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }

      .title33 {
        position: absolute;
        bottom: 2vh;
        right: 12vw;
        transition: transform 0.3s ease-out, height 0.5s ease-out;
        cursor: pointer;
        width: 25vw;
        padding: 2vh 3vw;
        font-size: 1.1vw;
        font-family: 'Arial', sans-serif, 'Microsoft YaHei';
        font-weight: bold;
        color: #13237a;
        line-height: 4vh;
        overflow: hidden; /* 隐藏溢出的内容 */
        .hoverImg {
          height: 3vw;
          width: fit-content;
          margin: 2vh auto;
        }

        .hoverTitleup {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 1vh auto;
          text-align: center;
          cursor: pointer;
          transition: transform 0.3s ease-out;
          font-size: 1.3vw;
          font-weight: bold;
        }

        .hoverTitleup2 {
          display: flex;
          align-items: center;
          margin: 0 auto 1vh auto;
          text-align: justify;
          cursor: pointer;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out; /* 添加高度和透明度过渡 */
          font-size: 1.1vw;
          font-weight: bold;
          opacity: 0;
          max-height: 0; /* 初始最大高度为0 */
          overflow: hidden; /* 隐藏溢出的内容 */
        }

        .hoverTitle {
          display: flex;
          margin-right: 2vw;
          cursor: pointer;
          font-weight: normal;
          transition: transform 0.3s ease-out;
        }
      }

      .title33:hover {
        background-image: url("../assets/imgback.png");
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        border-radius: 9px;
        height: fit-content; /* 悬停时高度增加 */
        .hoverTitleup2 {
          display: flex;
          opacity: 1; /* 悬停时变为完全不透明 */
          max-height: 15vh; /* 悬停时的最大高度，根据实际内容调整 */
        }
      }
    }


    //.layer3 {
    //  position: absolute;
    //  top: 5.06798516687268vh;
    //  right: 11.61178509532062vw;
    //  width: 47.42923165800116vw;
    //  height: 72.80593325092707vh;
    //  background-image: url("../assets/GPTplant.png");
    //  background-size: 100% auto;
    //  background-position: center;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  opacity: 1;
    //}
  }

  .screenConsult {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 15vw;
    background: transparent;

    .title {

      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh;
      text-align: left;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 80vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      height: fit-content;
      padding-top: 10vh;
      overflow-x: hidden;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-bottom-right-radius: 53px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 9px;
        padding: 1vh 1vw 2vh 1vw;
        opacity: 1;
        position: relative;
        background-image: url("../assets/groundback.png");
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        height: fit-content;

        .back {
          width: 100%;
          margin: -8vh auto 0 0;
          height: fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {
          width: 100%;
          height: fit-content;
          margin-top: 2vh;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 5%;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 13vw;
          display: flex;
          margin: -5vh 0 0 5%;
          border-radius: 9px;
          height: fit-content;
          position: relative;
        }

        .photohover {
          position: absolute;
          bottom: 0;
          left: 0; /* 初始位置在容器左侧外面 */
          width: 0%; /* 初始宽度为0 */
          height: 2vh;
          background-color: #2f6dfd;
          transition: width 0.5s ease; /* 平滑过渡效果 */
        }

        .photolist {
          font-size: 4.5vw;
          color: #e3e8ed;
          margin: auto 1vw 0 1vw;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 1vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;

          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 0.9vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 2vh;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }

      .grand1:hover {
        .photohover {
          width: 100%; /* 鼠标悬浮时宽度变为100% */
        }
      }

    }

  }

  .screenConsultnew {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: transparent;


    .title1 {

      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light1 {
      margin: 0 auto 0 auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .title {

      margin-top: 6vh;
      height: 5vh;
      opacity: 1;
      font-size: 1.5vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh;
      text-align: left;
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 80vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .serviceStyle {
      margin-top: 2vh;
      overflow-x: hidden;

      .box1 {
        box-shadow: 5px 0 14px 0px #D4E4FF;
        margin: 10vh 5px 0 5px;
        padding: 1vh 1vw 1vh 1vw;
        height: 55vh;
        border-bottom-right-radius: 33px;
        background-image: url("../assets/newback.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;

        .back1 {

          width: 100%;
          height: fit-content;
          margin-top: 15px;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 1vh 1vw;
          justify-content: space-between;
        }

        .back {
          display: flex;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 13vw;
          display: flex;
          margin: -10vh 0 0 5%;
          border-radius: 9px;
          height: fit-content;
        }

        .photolist {

          //background-image: url("../assets/newicon1.png");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          background-color: transparent;
          color: #e3e8ed;
          margin: auto auto 0 auto;
          height: 100%;
          width: 3vw;
          font-weight: bold;
        }

        .title {
          padding: 0;
          height: fit-content;
          opacity: 1;
          font-size: 1vw;
          margin: 15px 0 0 5%;
          text-align: left;
          //color: #53A3F7;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 700;
          line-height: 3vh;
        }

        .light {
          margin: 1vh 0 0 5%;
          text-align: justify;
          height: fit-content;
          font-size: 0.9vw;
          width: 90%;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2C3E50;
          line-height: 3vh;

          .lightbehind {
            cursor: pointer;
            transition:transform 0.3s ease-out;
            display: flex;
            border: #8faffa 1px solid;
            background-color: #e4ecfd;
            margin-top: 1vh;
            border-radius: 53px;
            padding: 5px 5px;
          }
          .lightbehind:hover{
            color: #2f6dfd;
          }

          .biaoti {
            width: 1vw;
            height: auto;
            margin: auto 0.5vw auto 1vw;
          }

          .iconStyle {
            margin: auto 1vw auto auto;
            //animation: pulse 2s infinite;
          }

          //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
        }
      }

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 1.8vh;

      .grand1 {
        cursor: pointer;
        width: 25.30329289428076vw;
        min-height: fit-content;
        padding: 0.98887515451174vh 0.12432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 5px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 3vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 19vw;
          height: 17.44004944375773vh;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;
          text-align: left;
          width: 12vw;

          height: 3vh;
          font-size: 0.97094165222415vw;
          white-space: nowrap; //换行
          font-family: Roboto, sans-serif;
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          //text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.51940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand1:hover {
        .back {
          box-shadow: 5px 0 14px 0px #9abaf3;
        }
      }

      .grand2 {
        cursor: pointer;
        width: 100%;
        min-height: 32.65636588380717vh;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          width: 100%;
          height: 100%;
          background: #EFF4FF;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          position: relative;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          //margin: 3vh auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: fit-content;
          //height: 4.73176761433869vh;
          .mengban {
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 20%;
            background: linear-gradient(to top, rgba(54, 64, 73, 0.9) 0%, rgba(54, 64, 73, 0.4) 50%, transparent 100%);
            opacity: 0.9;
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;

            .title1 {
              position: absolute;
              height: 100%;
              color: white;
              margin-top: unset
            }
          }
        }

        .title1 {
          opacity: 4;
          margin-top: 2.22496909765142vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.17094165222415vw;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.5vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          //word-break: break-all;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.80878105141537vw;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          //color: #2168DB;
          line-height: 3.1940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      //.grand2:hover {
      //  .back {
      //    background-color: rgb(135, 103, 215);
      //  }
      //
      //  .photo {
      //    display: none;
      //  }
      //
      //  .title1 {
      //    position: absolute;
      //    left:1.8vw;
      //    top: 2.22496909765142vh;
      //    height: 2.71940667490729vh;
      //    color: white;
      //    font-size: 0.97094165222415vw;
      //  }
      //
      //  .title2 {
      //    word-break: normal;
      //    margin-top: 8.22496909765142vh;
      //    color: white;
      //    font-size: 0.80878105141537vw;
      //  }
      //
      //  .consult {
      //    position: absolute;
      //    left: 1.8vw;
      //    cursor: pointer;
      //    display: block;
      //    color: white;
      //  }
      //}
    }

    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #d4e4ff;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }

    .pro4 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      padding: 0 10vw;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 40vh;
          background: #c0d8f9;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 1.25;
          margin: auto;
          background-color: white;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 30vh;
        }


        .title1 {

          opacity: 4;
          margin-top: 3vh;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }


    }


  }

  .servicegrand {
    background-color: #123a74;
    width: 80vw;
    height: fit-content;
    margin: 4vh 0 0 0;

    .servicegrandback {
      padding: 4vh 4vh 0vh 4vh;

      .title {
        color: white;
        margin: 0;
        height: unset;
      }

      .light {
        width: fit-content;
        color: white;
        margin: unset;
        padding: 1vh 0 1vh 0
      }
    }
  }

  .servicecontent {
    display: grid;
    grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)  minmax(0, 1fr);
    width: 80vw;
    height: fit-content;
    background: linear-gradient(#123a74 50%, white 50%);

    .contentcard {
      height: fit-content;
      width: 19vw;
      background-color: #95d1d1;
      padding: 2vh 2vw 2vh 2vw;
      margin: auto
    }

    .contentcard :hover {
      cursor: pointer;
    }

    .servicecontenttitle {
      color: black;
      text-align: center;
      font-size: 1.0vw;
      font-weight: bolder;
    }
  }

  .screenGpts {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: transparent;


    .title {

      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr)  minmax(0, 1fr);
      column-gap: 0;
      row-gap: 2vh;

      .grand2 {
        cursor: pointer;
        width: 100%;

        height: fit-content;
        padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
        opacity: 1;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          position: relative;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 35vh;
        }


        .title1 {
          padding: 5vh 0;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 3.5vh;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }

      .grand2:hover {
        .back {
          transform: translateY(-10px);

        }

      }
    }


  }

  .screenConsult1 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: transparent;


    .title {

      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        min-height: fit-content;
        //padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.19324090121317vw;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          min-height: 20vh;
          //background: white;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          //border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 3vh 0 2vh 0.8vw;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          border-radius: 9px;
          height: fit-content;
        }

        .titleAligen {
          position: absolute;
          left: 1.5vw;
          top: 3vh;
          text-align: justify;
          width: 90%;
        }

        .title1 {
          opacity: 4;
          margin: 3.22496909765142vh 0.85540150202195vw 1.22496909765142vh 0.85540150202195vw;;
          width: 25vw;

          height: 3vh;
          font-size: 1vw;
          white-space: nowrap; //换行
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .title2 {
          opacity: 4;
          margin: 0.94437577255871vh 0.85540150202195vw 0.94437577255871vh 1.54437577255871vh;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 3.5vh;
          height: fit-content;
        }

        .consult {
          display: none; /* Hide by default */
          position: absolute;
          left: -100%; /* Start off-screen to the left */
          transition: left 5s ease; /* Animate the left property */
          padding: 2.5vh 1.5vw;
          color: white;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          height: 8vh;
          width: 95.5%;
          background: url("../assets/froundHover.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          font-family: Arial, sans-serif, "Microsoft Yahei"; /* Corrected typo 'Sarial' */
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 1vh;

        }
      }

      .grand1:hover .consult {
        display: block; /* Show on hover */
        left: 1vw; /* Slide into view */

      }

    }


  }

  .screen3 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: white;


    .title {

      height: 5vh;
      opacity: 1;
      text-align: center;
      margin: 0 auto;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }


    .pro2 {

      border-radius: 9px;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      column-gap: 0;

      .grand2 {
        transition: transform 0.5s ease;
        border-radius: 9px;
        cursor: pointer;
        width: 100%;
        min-height: 30.65636588380717vh;
        padding: 0.98887515451174vh 0.5vw 0.98887515451174vh 0.5vw;
        opacity: 1;
        position: relative;

        .back {

          width: 100%;
          height: 100%;
          //background: #D4E4FF;;
          //box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }

        .photo {

          border-radius: 9px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          border: 9px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: fit-content;
        }

        .title1 {
          position: absolute;
          left: 5vh;
          top: 5vh;
          opacity: 4;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2.71940667490729vh;
          font-size: 1.2vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          color: #2168DB;
          line-height: 2.71940667490729vh;
        }


      }

      .grand2:hover {

        transform: translateY(-10px);

      }
    }


  }

  .screen8 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 5vh 11.09763142692085vw 5vh 8.09763142692085vw;
    background: white;

    .title {

      text-align: center;
      height: 5vh;
      opacity: 1;
      font-size: 2vw;
      color: #15257b;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 3vh
    }

    .light {
      margin: 0 auto 4vh auto;
      text-align: justify;
      width: 66vw;
      height: fit-content;
      font-size: 1.0287117273vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #15257b;
      line-height: 4.94437577255871vh;

    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      //padding: 0 11.09763142692085vw 0 8.09763142692085vw;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
      row-gap: 2vh;
    }

    .pro2:hover {
      transform: translateY(-10px);
    }

  }

  ::v-deep .el-tooltip__content {
    font-size: 1.2vw !important; /* 你想要的字体大小 */
  }


}

.totalModel {
  background: #bed8f9;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;

  .map {
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }
  }

  .GEGPTs {
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 98%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      height: fit-content;
      width: 100%;
      margin-top: 55px;
      //z-index: 89156;

      .grand1 {
        cursor: pointer;
        width: 100%;
        border-bottom-right-radius: 53px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 9px;

        padding: 15px 20px 15px 20px;
        opacity: 1;
        position: relative;
        background-image: url("../assets/groundback.png");
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        height: fit-content;

        .back {
          width: 100%;
          margin: -50px auto 0 0;
          height: fit-content;
          border-radius: 9px;
          // border-radius:6px;
          // display: flex;
        }

        .back1 {

          width: 100%;
          height: fit-content;
          margin-top: 15px;
          border-top: none;
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
          // border-radius:6px;
          padding: 0 15px 10px 15px;
          justify-content: space-between;
        }

        .photo {
          //background-image: url("../assets/pro1.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          width: 40vw;
          display: flex;
          margin: -20px 0 0 5%;
          border-radius: 9px;
          height: fit-content;
        }

        .photolist {
          font-size: 55px;
          color: #e3e8ed;
          margin: auto 0 0 15px;
          height: 100%;
          font-weight: bold;
        }

        .title1 {
          text-align: left;
          font-size: 16px;
          font-family: Roboto, sans-serif;
          font-weight: bold;
          width: 60vw;
          overflow: hidden;
          height: fit-content;
          //line-height: 35px;
        }

        .title2 {
          display: flex;

          opacity: 4;
          width: fit-content;
          height: fit-content;
          // text-align: justify;
          font-size: 14px;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          color: white;
          margin-top: 10px;
          border-radius: 23px;
          padding: 5px 15px;
          background-color: #2168DB;
          //line-height: 35px;
        }
      }

    }

    .GEGPTs_back_card {
      height: 96px;
      background-color: #d9e6f6;
      margin: 5px;
      background-image: url("../assets/beijing.png");
      display: flex;

      .card_img {
        height: 30px;
        width: fit-content;
        object-fit: contain;
        padding: 10px;
        margin: auto;
      }

      .title {
        font-size: 15px;
        margin: auto 2px;
        font-weight: bolder;
        padding: 0 2px;
        text-align: left;
      }
    }

  }


  .up {
    cursor: pointer;
    position: fixed;
    top: 90%;
    right: 3%;
    padding: 5px;
    width: 45px;
    height: 45px;
    z-index: 999999999;
    border-radius: 50%;
    //border: 5px solid #1295d9;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .screen1 {
    z-index: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 0 0 30px 0;
    background-image: url("../assets/backModelnew.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    /* Add any additional styling as needed */

    .layer2 {
      .title1 {
        margin: 0 auto;
        width: fit-content;
        height: 23px;
        font-size: 23px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #111111;
        line-height: 5vh;
      }

      .title2 {
        margin: 35px auto 0 auto;
        width: fit-content;
        height: 17px;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #111111;
        line-height: 24px;
      }

      .title3 {
        display: flex;
        text-align: center;
        margin: 20px auto 0 auto;
        width: fit-content;
        height: 35px;
        font-size: 8px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: #2f6eff;
        line-height: 22px;
      }
    }

    .layer3 {
      position: absolute;
      display: flex;
      bottom: 15px;
      opacity: 1;
      width: 90%;
      justify-content: center;

      .communicity {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2f6eff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

      .communicityGpt {
        z-index: 8;
        cursor: pointer;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center; /* Vertically center the content */
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 10px 4.6875vw;
        height: 35px;
        background-color: #2f6eff;
        border: #2f6eff 1px solid;
        overflow: hidden; /* Add overflow property */
      }

    }
  }


  .seriesService {
    background-color: white;
    padding: 0 10px 20px 10px;

    .title {
      padding: 20px 0 10px 0;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      text-align: center;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 0px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .box1 {
      box-shadow: 5px 0 14px 0px #D4E4FF;
      margin: 85px 5px 0 5px;
      padding: 10px 10px 20px 10px;
      height: 350px;
      border-bottom-right-radius: 33px;
      background-image: url("../assets/newback.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      .back1 {

        width: 100%;
        height: fit-content;
        margin-top: 15px;
        border-top: none;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        // border-radius:6px;
        padding: 10px 15px;
        justify-content: space-between;
      }

      .back {
        display: flex;
      }

      .photo {
        //background-image: url("../assets/pro1.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        opacity: 1.25;
        width: 40vw;
        display: flex;
        margin: -75px 0 0 5%;
        border-radius: 9px;
        height: fit-content;
      }

      .photolist {

        //background-image: url("../assets/newicon1.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
        color: #e3e8ed;
        margin: auto auto 0 auto;
        height: 100%;
        width: 10vw;
        font-weight: bold;
      }

      .title {
        padding: 0;
        height: fit-content;
        opacity: 1;
        font-size: 16px;
        margin: 15px 0 0 5%;
        text-align: left;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;
      }

      .light {
        margin: 15px 0 0 5%;
        text-align: justify;
        height: fit-content;
        font-size: 12px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 28px;

        .lightbehind {
          display: flex;
          border: #8faffa 1px solid;
          background-color: #e4ecfd;
          margin-top: 10px;
          border-radius: 53px;
          padding: 5px 5px;
        }

        .biaoti {
          width: 15px;
          height: auto;
          margin: auto 10px auto 5px;
        }

        .iconStyle {
          margin: auto 5px auto auto;
          //animation: pulse 2s infinite;
        }

        //@keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }
      }
    }


  }

  .screenConsultnew {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: #D4E4FF;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screenGpts {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screen3 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: white;

    .title {
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      text-align: center;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro3 {
      //margin-top: -4vh;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 15px;
      row-gap: 0;

      .grand2 {
        cursor: pointer;
        width: 100%;
        height: fit-content;
        padding: 15px 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          height: 100%;
          background: white;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
        }


        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 0 auto auto auto;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 150px;
        }

        .title1 {
          opacity: 4;
          margin: 15px auto;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          font-size: 16px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 1.94437577255871vh 1.35540150202195vw 2.94437577255871vh 1.35540150202195vw;
          text-align: justify;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 2.71940667490729vh;
        }

        .consult {
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.9vw;
          bottom: 3.94437577255871vh;
          left: 1.85540150202195vw;
        }
      }


    }


  }

  .screenConsult1 {
    z-index: 10;
    width: 100vw;
    border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }


    .pro2 {
      width: 100%;
      z-index: 89156;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      column-gap: 0;
      row-gap: 0;

      .grand1 {
        transition: transform 0.5s ease;
        cursor: pointer;
        width: 100%;
        padding: 15px 0 0 0;
        opacity: 1;
        position: relative;

        .back {
          transition: transform 0.5s ease;
          width: 100%;
          background: white;;
          box-shadow: 5px 0 14px 0px #D4E4FF;
          border: 2px solid #FFFFFF;
          border-radius: 9px;
          display: flex;
        }

        .photo {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent; /* 这里设置背景颜色为透明 */
          opacity: 1.25;
          margin: 15px 0 0 15px;
          display: flex;
          justify-content: center;
          text-align: center;
          width: 125px;
          border-radius: 9px;
          height: 110px;
        }


        .title1 {
          opacity: 4;
          margin: 15px 15px 5px 15px;
          text-align: center;

          height: fit-content;
          font-size: 16px;
          white-space: nowrap; //换行
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: bold;
          overflow: hidden;

          text-overflow: ellipsis;
          //color: #2168DB;
          line-height: 22px;
        }

        .title2 {
          opacity: 4;
          margin: 0 15px 15px 15px;
          text-align: justify;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          //color: #2168DB;
          line-height: 22px;
          height: fit-content;
          max-height: 88px;
        }

        .consult {
          transition: transform 0.5s ease;
          position: absolute;
          display: none;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 500;
          font-size: 0.80878105141537vw;
          bottom: 3.94437577255871vh;
        }
      }


    }


  }

  .screen8 {
    z-index: 10;
    width: 100vw;
    height: fit-content;
    //border-radius: 9px;
    padding: 25px 20px;
    background: transparent;

    .title {

      text-align: center;
      height: fit-content;
      opacity: 1;
      font-size: 20px;
      //color: #53A3F7;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 700;
      line-height: 20px;
    }

    .light {
      margin: 15px auto 0 auto;
      text-align: justify;
      width: 90%;
      height: fit-content;
      font-size: 16px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2C3E50;
      line-height: 22px;

    }

    .pro2 {
      transition: transform 0.5s ease;
      height: fit-content;
      width: 100%;
      z-index: 89156;
      padding: 15px;
      display: grid;
      grid-template-columns:minmax(0, 1fr);
      //column-gap: 1vw;
    }


  }

}
</style>

